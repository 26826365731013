<style>
</style>

<template>
  <v-expansion-panel :class="genClass()">
    <v-expansion-panel-header>
      <v-col>{{ ind + 1 }}</v-col>
      <v-col>{{ order.identifier }}</v-col>
      <v-col>{{ order.receiver_data.name }}</v-col>
      <v-col>{{ order.receiver_data.denomination }}</v-col>
      <v-col>{{ order.status_label }}</v-col>
      <v-col>{{ translateInventoryStatus(inventoryStatus) }}</v-col>
      <v-col> {{ itemsChecked }} / {{ order.itemsExpected }} </v-col>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <inventory-content
        ref="inventoryContent"
        :orderpass="order"
        :mov="mov"
        @status="updatelocalstatus"
        :orderstatuses="orderStatus"
        @saved="$emit('saved')"
        @failed="$emit('failed')"
        @changeMovement="changeMovement"
        @movement-created="emitMovementCreated"
        :inventory="inventory"
        :includeItemNotWaited="includeItemNotWaited"
      ></inventory-content>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script type="text/javascript">
import inventoryContent from "./InventoryContent.vue";

export default {
  name: "inventory-row",

  props: {
    order: {
      type: Object,
    },
    ind: {
      type: Number,
    },
    disabletodel: {
      type: Boolean,
      default: false,
    },
    mov: {
      type: Array,
    },
    orderStatus: {
      type: Array,
    },
    inventory: {
      type: Object,
    },
    includeItemNotWaited: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.itemsChecked = this.order.itemsChecked;
    this.inventoryStatus = this.order.inventoryStatus;
  },

  watch: {
    order: {
      handler(newVal) {
        this.itemsChecked = newVal.itemsChecked;
        this.inventoryStatus = newVal.inventoryStatus;
      },
      deep: true,
    },
  },

  data() {
    return {
      itemsChecked: 0,
      inventoryStatus: "",
    };
  },

  methods: {
    inventoryContentRefName(){
      return 'inventory-content-' + this.order.id
    },
    enableMovement(item_id){
      this.$refs.inventoryContent.enableMovement(item_id)
    },
    emitMovementCreated(item_id){
      this.$emit('movement-created', item_id, this.order.id)
    },
    updatelocalstatus: function (value1) {
      var ord = {
        id: this.order.id,
        status: value1,
        status_label: null
      }
      this.orderStatus.forEach((t) => {
        if (t[0] == value1) {
          ord.status_label = t[1];
        }
      });
      this.$emit('update-order-status', ord)
    },
    genClass: function () {
      return {
        "green lighten-2": this.inventoryStatus === "scan_completed",
        "yellow lighten-2": this.inventoryStatus === "scan_partial",
      };
    },
    changeMovement(o) {
      this.$emit("changeMovement", o);

      if (this.includeItemNotWaited)
        this.$set(
          this,
          "itemsChecked",
          o["items"].filter((i) =>
            i.checkedQuantity
              ? i.checkedQuantity == i.expected_stock * i.unitary_pieces
              : false
          ).length
        );
      else
        this.$set(
          this,
          "itemsChecked",
          o["items"].filter((i) =>
            i.checkedQuantity ? i.checkedQuantity == i.stock : false
          ).length
        );
      this.$set(this, "inventoryStatus", this.getInventoryStatus(o));
    },
    getInventoryStatus(o) {
      if (this.order.itemsChecked == 0) return "not_scanned";
      return o["items"].length != this.order.itemsChecked
        ? "scan_partial"
        : "scan_completed";
    },
    translateInventoryStatus(status) {
      if (status === "not_scanned")
        return this.$t("enum.order.scanned_status.not_scanned");
      if (status === "scan_partial")
        return this.$t("enum.order.scanned_status.partially_scanned");
      if (status === "scan_completed")
        return this.$t("enum.order.scanned_status.fully_scanned");
    },
  },

  components: {
    inventoryContent,
  },
};
</script>
