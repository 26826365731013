<template>
  <v-dialog
    v-model="dialog"
    :persistent="true"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on" v-if="!delivery">
        <v-list-item-icon>
          <v-icon>mdi-plus-box</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{
              $t(
                "activerecord.attributes.delivery.request_logistics_activities"
              )
            }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-icon v-bind="attrs" v-on="on" v-if="delivery" @click="compileAttributes()">
        mdi-pencil
      </v-icon>
    </template>
    <new-delivery-with-orders
      ref="NewDeliveryWithOrders"
      @close-dialog="dialog = false"
      @refresh-delivery-table="refreshDeliveryTable()"
      :sender-id="senderId"
      :company-id="companyId"
      :extra-activities="extraActivities"
      :delivery-kinds="deliveryKinds"
      :sender-location="senderLocation"
      :delivery-with-order-identifier="deliveryWithOrderIdentifier"
      :sender-username="senderUsername"
      :company-email="companyEmail"
      :old-delivery="delivery_attributes"
    />
  </v-dialog>
</template>

<script>
/* eslint-disable prettier/prettier */
import NewDeliveryWithOrders from "./NewDeliveryWithOrders.vue";
import RequestService from "@/services/request.service.js";

export default {
  name: "delivery-with-orders-dialog",
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    senderId: {
      type: Number,
      required: true,
    },
    additionalDataSchema: {
      type: Object,
      required: true,
    },
    senderLocation: {
      type: Object,
      required: true,
    },
    senderUsername: {
      type: String,
      required: true,
    },
    companyEmail: {
      type: String,
      required: true,
    },
    delivery: {
      type: Object,
      default: null,
    }
  },
  components: {
    NewDeliveryWithOrders,
  },
  watch: {
    dialog(val) {
      if (val) {
        this.setDeliveryIdentifier();
      }
    }
  },
  data() {
    return {
      dialog: false,
      deliveryKinds: [
        { text: "Ritiro", value: "pickup" },
        { text: "Consegna", value: "delivery" },
      ],
      extraActivities: this.getExtraActivitiesOptions(),
      deliveryWithOrderIdentifier: "",
      delivery_attributes: JSON.parse(JSON.stringify(this.delivery)),
    };
  },
  mounted(){
    this.setDeliveryIdentifier();
  },

  methods: {
    compileAttributes() {
      if (this.$refs.NewDeliveryWithOrders) {
        this.$refs.NewDeliveryWithOrders.addAttrsToDelivery();
      }
    },
    refreshDeliveryTable(){
      this.$emit("refresh-delivery-table");
    },
    getExtraActivitiesOptions() {
      var options = [];
      if (this.delivery_attributes) {return this.delivery_attributes}
      if (
        this.additionalDataSchema != null &&
        this.additionalDataSchema.schema != null &&
        this.additionalDataSchema.schema.extra_activity != null
      )
        options = this.additionalDataSchema.schema.extra_activity.options;

      return options.map((e) => ({
        text: e[0],
        value: e[1],
      }));
    },
    setDeliveryIdentifier() {
      var that = this;
      if (this.delivery) { return; }
      var address = "senders/" + this.senderId + "/get_new_delivery_identifier";
      RequestService.request_get({}, address).then(
        (response) => {
          that.deliveryWithOrderIdentifier = response.data.identifier;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>