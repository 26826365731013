<style>
</style>

<template>
  <tr :class="genClass()">
    <td>{{ (item.expo ? "EXPO - " : "") + item.name }}</td>
    <td>
      {{ item.identifier }}
      <span v-if="item.order_line">/ {{ item.order_line }}</span>
    </td>
    <td>{{ item.serial_number }}</td>
    <td>{{ item.unitary_pieces }}</td>
    <td>{{ item.expected_stock }}</td>
    <td>{{ item.stock }}</td>
    <td  style="width: 20vh;">
      <v-btn v-if="!inventory.completed"
        x-small
        color="warning"
        :disabled="qtaMoved <= 0 || disableMovement"
        @click="createMovement('out')"
      >
        <h2>-</h2>
      </v-btn>
      {{ qtaMoved }}
      <v-btn v-if="!inventory.completed"
        x-small
        color="success"
        @click="createMovement('in')"
        :disabled="disableMovement"
      >
        <h2>+</h2>
      </v-btn>
    </td>
    <td v-if="!inventory.completed">
      <v-btn
        x-small
        color="primary"
        :disabled="completed || qtaMoved > item.expected_stock || disableMovement"
        @click="createMovement('confirm')"
      >
        <v-icon> mdi-check </v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script type="text/javascript">
import moment from "moment";
import RequestService from "@/services/request.service.js";

export default {
  name: "item-row",

  props: {
    value: {
      type: Object,
    },
    index: {
      type: Number,
    },
    ismov: {
      type: Boolean,
      default: true,
    },
    inventory: {
      type: Object,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    companyUseWarehousePlace: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
      required: true,
    },
    notPresentinInventory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      item: JSON.parse(JSON.stringify(this.value)),
      disableMovement: false,
    };
  },
  methods: {
    invenotryIdentifier() {
      if (!this.inventory || this.notPresentinInventory) return "";
      return this.inventory.identifier || "";
    },
    inventoryDate() {
      if (!this.inventory || this.notPresentinInventory) return "";
      return moment(this.inventory.date).format("DD/MM/YYYY") || "";
    },
    genClass: function () {
      return {
        warning: this.item.damaged,
        "grey lighten-1": this.notPresentinInventory,
        "green lighten-3": this.completed,
      };
    },
    createMovement(type){
      var that = this;
      var kind = 'in'
      var qta = 1
      var bas = this.item.movements.reduce((acc, cur) => acc + cur.quantity, 0)
      if (type == 'out') {
        kind = 'out'
        qta = -1
      }
      if (type == 'confirm') {
        qta = this.item.expected_stock - bas
      }
      this.disableMovement = true;
      const params = {
        order_id: this.orderId,
        kind: kind,
        from_open: true,
        movement: {
          quantity: qta,
          item_id: this.item.id,
          kind: kind,
          warehouse_area_id:
            this.$store.state.auth.session.user.warehouse_area_id,
          place_id: null,
          suggested_place_id: null,
          damaged: false,
          inventory_id: this.inventory.id,
        },
      };
      RequestService.request_post(params, "items/move")
        .then((res) => {
          console.log(res);
          that.$emit('movement-created', this.item.id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    enableMovement(){
      this.disableMovement = false;
    }
  },
  watch: {
    value: {
      handler(val) {
        this.item = val;
      },
      deep: true,
    },
    item: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  computed: {
    qtaMoved() {
      return (this.item.movements || [])
        .map((m) => parseFloat(m.quantity))
        .reduce((a, c) => a + c, 0.0);
    },
  },
};
</script>