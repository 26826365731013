<template>
  <v-card>
    <v-toolbar dark color="#343a40">
      <v-btn icon dark @click="closeDialog()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <confirm-dialog 
          @confirmed="createNewDeliveryWithOrders()"
          :title="$t('common.operation_confimed')"
          :text="$t('common.warn_extra_activity_confirmation')"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark text v-bind="attrs" v-on="on">{{$t('common.confirm')}}</v-btn>
          </template>
        </confirm-dialog>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <br />
      <v-layout align-center justify-center column fill-height v-if="loading">
        <v-flex row align-center>
            <v-progress-circular indeterminate :size="50" color="primary" class=""></v-progress-circular>
        </v-flex>
      </v-layout>
      <template v-else>
        <v-container fluid>
          <v-row>
            <v-col md="4">
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('activerecord.attributes.delivery.identifier')"
                    type="string"
                    v-model="delivery.identifier"
                    hide-details="auto"
                    outlined
                    dense
                    :error-messages="errorVisible ? errorMessages.identifier : []"
                    :disabled="isOldDelivery"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <x-date-time-picker
                    v-model="delivery.first_date"
                    :label="$t('activerecord.attributes.delivery.first_date')"
                    outlined
                    dense
                    hide-details="auto"
                    :onlyDate="true"
                    :change="setFirstDateHour()"
                    :error-messages="errorVisible ? errorMessages.first_date : []"
                  />
                </v-col>
                <v-col>
                  <x-date-time-picker
                    v-model="delivery.last_date"
                    :label="$t('activerecord.attributes.delivery.last_date')"
                    outlined
                    dense
                    hide-details="auto"
                    :onlyDate="true"
                    :change="setLastDateHour()"
                    :error-messages="errorVisible ? errorMessages.last_date : []"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="delivery.service_type"
                    :items="deliveryKinds"
                    outlined
                    dense
                    hide-details
                    :label="$t('activerecord.models.service_type.one')"
                  />
                </v-col>
                <v-col>
                  <v-select
                    v-model="delivery.additional_data.extra_activity"
                    :items="extraActivities"
                    outlined
                    dense
                    hide-details
                    @change="handleAddressData()"
                    :label="$t('activerecord.attributes.delivery.extra_activity')"
                    :disabled="isOldDelivery"
                  />
                </v-col>
              </v-row>
              <v-row v-if="!!delivery.additional_data.extra_activity">
                <v-col cols="4" justify="center">
                  <v-text-field
                    v-model="delivery.province"
                    :label="$t('activerecord.attributes.delivery.province')"
                    outlined
                    dense
                    hide-details="auto"
                    :error-messages="errorVisible ? errorMessages.province : []"
                  />
                </v-col>
                <v-col cols="4" justify="center">
                  <v-text-field
                    v-model="delivery.town"
                    :label="$t('activerecord.attributes.delivery.town')"
                    outlined
                    dense
                    hide-details="auto"
                    :error-messages="errorVisible ? errorMessages.town : []"
                  />
                </v-col>
                <v-col cols="4" justify="center">
                  <v-text-field
                    v-model="delivery.zipcode"
                    :label="$t('activerecord.attributes.delivery.zipcode')"
                    outlined
                    dense
                    hide-details="auto"
                    :error-messages="errorVisible ? errorMessages.zipcode : []"
                  />
                </v-col>
              </v-row>
              <v-row v-if="!!delivery.additional_data.extra_activity">
                <v-col cols="12" justify="center">
                  <v-text-field
                    v-model="delivery.street"
                    :label="$t('activerecord.attributes.delivery.street')"
                    outlined
                    dense
                    hide-details="auto"
                    :error-messages="errorVisible ? errorMessages.street : []"
                  />
                </v-col>
              </v-row>
              <v-row v-if="!!delivery.additional_data.extra_activity">
                <v-col cols="4" justify="center">
                  <v-text-field
                    v-model="delivery.additional_data.recipient"
                    :label="$t('activerecord.models.receiver.one')"
                    outlined
                    dense
                    hide-details="auto"
                    :error-messages="errorVisible ? errorMessages.recipient : []"
                  />
                </v-col>
                <v-col cols="4" justify="center">
                  <v-text-field
                    v-model="delivery.additional_data.contacts"
                    :label="$t('activerecord.models.contact.one')"
                    contact
                    outlined
                    dense
                    hide-details="auto"
                    :error-messages="errorVisible ? errorMessages.contacts : []"
                  />
                </v-col>
                <v-col cols="4" justify="center">
                  <v-text-field
                    v-model="delivery.additional_data.floor"
                    :label="$t('common.floor.one')"
                    outlined
                    dense
                    hide-details="auto"
                    :error-messages="errorVisible ? errorMessages.floor : []"
                  />
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col v-if="canShowPromo()">
                  <v-btn block @click="createLayout('layout_promo')" color="primary" :disabled="requestOrderLoading">{{$t('common.layout.promo')}}</v-btn>
                </v-col>
                <v-col  v-if="canShowPromo()">
                  <v-btn block @click="createLayout('layout_change')" color="primary" :disabled="requestOrderLoading">{{$t('common.layout.change')}}</v-btn>
                </v-col>
                <v-col>
                  <v-btn block @click="clearOrders()" color="error" :disabled="requestOrderLoading">{{$t('common.clear')}}</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center">
                  <v-btn-toggle block v-model="filterBySender" mandatory dense>
                    <v-btn :value="true">{{$t('common.shop_orders.single')}}</v-btn>
                    <v-btn :value="false">{{$t('common.shop_orders.logistics')}}</v-btn
                    >
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row v-if="addOrderOrItemDisabled()">
                <v-col>
                  <add-order-field
                    @add-order="addOrder($event)"
                    :sender-id="senderId"
                    :filter-by-sender="filterBySender"
                    outlined
                    dense
                    hideDetails
                    ref="addOrderField"
                    @snackbar="addMessageOnSnackbar($event)"
                  />
                </v-col>
              </v-row>
              <v-row v-if="addOrderOrItemDisabled()">
                <v-col>
                  <add-item-field
                    @add-item="addItem($event)"
                    :sender-id="senderId"
                    :filter-by-sender="filterBySender"
                    outlined
                    dense
                    hideDetails
                    ref="addItemField"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="delivery.notes"
                    :label="$t('activerecord.models.delivery_note.other')"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-layout align-center justify-center column fill-height v-if="requestOrderLoading">
                <v-flex row align-center>
                    <v-progress-circular indeterminate :size="50" color="primary" class=""></v-progress-circular>
                </v-flex>
              </v-layout>
              <v-row v-if="delivery.last_logistic_service != null">
                <v-col>
                  <v-alert
                    dismissible
                    prominent
                    :value="true"
                    :type="delivery.last_logistic_service.last_sync_code >= 200 
                      && delivery.last_logistic_service.last_sync_code < 300 ? 'success' : 'error'"
                  >
                    <b>{{ 
                      delivery.last_logistic_service.last_sync_code >= 200 
                        && delivery.last_logistic_service.last_sync_code < 300 
                        ? $t('common.last_logistic_service_alert.success')
                        : $t('common.last_logistic_service_alert.error')
                    }}</b><br>
                    {{ delivery.last_logistic_service.last_sync_code + ": " 
                      + (delivery.last_logistic_service.last_sync_message || 'OK') }}
                  </v-alert>
                </v-col>
              </v-row>
              <v-expansion-panels>
                <p v-if="orders.length == 0">{{ $t('common.no_orders_select') }}</p>
                <order-panel
                  v-for="(order, index) in orders"
                  v-bind:key="orders[index].id"
                  v-model="orders[index]"
                  @update-order="updateOrder(orders[index].id)"
                  @remove-order="removeOrder(orders[index].id)"
                />
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-card-text>
    <v-snackbar
      v-model="snackbar.value"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      left
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-card>
</template>

<script>
/* eslint-disable prettier/prettier */
import XDateTimePicker from "../utilities/x_date_time_picker.vue";
import AddOrderField from "./AddOrderField.vue";
import AddItemField from "./AddItemField.vue";
import OrderPanel from "./OrderPanel.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

import moment from "moment";
import RequestService from "@/services/request.service.js";
const APP_URL = process.env.VUE_APP_URL;

export default {
  name: "new-delivery-with-orders",
  components: {
    XDateTimePicker,
    AddOrderField,
    AddItemField,
    OrderPanel,
    ConfirmDialog,
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    deliveryKinds: {
      type: Array,
      required: true,
    },
    extraActivities: {
      type: Array,
      required: true,
    },
    senderId: {
      type: Number,
      required: true,
    },
    senderLocation: {
      type: Object,
      required: true,
    },
    deliveryWithOrderIdentifier: {
      type: String,
      required: true,
    },
    senderUsername: {
      type: String,
      required: true,
    },
    companyEmail: {
      type: String,
      required: true,
    },
    oldDelivery: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      delivery: this.buildEmptyDelivery(),
      title: '',
      isOldDelivery: false,
      orders: [],
      errorVisible: false,
      providerName: this.$store.getters["auth/providerName"],
      snackbar: {
        color: "success",
        text: "",
        value: false,
        timeout: 5000,
      },
      filterBySender: true,
      layoutKind: '',
      loading: false,
      requestOrderLoading: false
    };
  },
  watch: {
    deliveryWithOrderIdentifier: {
      handler(val) {
        this.delivery.identifier = JSON.parse(JSON.stringify(val));
      },
      deep: true,
    },
  },
  computed: {
    errorMessages() {
      var errs = {};
      if (
        this.delivery.first_date == null ||
        this.delivery.first_date === "Invalid date"
      ) {
        errs.first_date = this.$t("activerecord.attributes.delivery.first_date")+ " " + this.$t("errors.messages.required");
      }
      /* eslint-disable-next-line */
      var invalidCharPattern = /[\/\\:*?"<>|%\&#$]/;
      if (invalidCharPattern.test(this.delivery.identifier)){
        errs.identifier = this.$t("activerecord.attributes.delivery.identifier")+ " " + this.$t("errors.messages.cannot_contain_special_caracters");
      }
      if (
        this.delivery.identifier.includes(" ")
      ) {
        errs.identifier = this.$t("activerecord.attributes.delivery.identifier")+ " " + this.$t("errors.messages.cannot_contain_spaces");
      }
      if (
        this.delivery.identifier == ""
      ) {
        errs.identifier = this.$t("activerecord.attributes.delivery.identifier")+ " " + this.$t("errors.messages.required");
      }
      if (
        this.delivery.last_date != null &&
        this.delivery.last_date !== "Invalid date" &&
        this.delivery.first_date != null &&
        this.delivery.first_date !== "Invalid date" &&
        moment(this.delivery.last_date).isBefore(this.delivery.first_date)
      ) {
        errs.last_date =
          this.$t("errors.messages.must_be_subsequent", {value1: this.$t("activerecord.attributes.delivery.first_date"), value2: this.$t("activerecord.attributes.delivery.last_date")})
      }
      var now = moment().startOf('day')
      if (moment(this.delivery.first_date).isBefore(now)){
        errs.first_date = this.$t("errors.messages.must_be_subsequent", {value1: this.$t("activerecord.attributes.delivery.first_date"), value2: this.$t("common.current_date")})
      }
      if (moment(this.delivery.last_date).isBefore(now)){
        errs.last_date = this.$t("errors.messages.must_be_subsequent", {value1: this.$t("activerecord.attributes.delivery.last_date"), value2: this.$t("common.current_date")})
      }
      if (!this.delivery.additional_data.extra_activity) return errs;

      if ((this.orders || "").length > 1) {
        errs.orders = this.$t("errors.messages.not_more_than_one_order_for_extra_activity");
      }

      if ((this.delivery.province || "").length != 2) {
        errs.province = this.$t("activerecord.attributes.delivery.province")+ " " + this.$t("errors.messages.wrong_length.other", {count: 2});
      }
      if (this.delivery.province == null || this.delivery.province === "") {
        errs.province = this.$t("activerecord.attributes.delivery.province")+ " " + this.$t("errors.messages.required");
      }
      if (this.delivery.town == null || this.delivery.town === "") {
        errs.town = this.$t("activerecord.attributes.delivery.town")+ " " + this.$t("errors.messages.required");
      }
      if (this.delivery.zipcode == null || this.delivery.zipcode === "") {
        errs.zipcode = this.$t("activerecord.attributes.delivery.zipcode")+ " " + this.$t("errors.messages.required");
      }
      if (this.delivery.street == null || this.delivery.street === "") {
        errs.street = this.$t("activerecord.attributes.delivery.street")+ " " + this.$t("errors.messages.required");
      }
      if (
        this.delivery.additional_data.recipient == null ||
        this.delivery.additional_data.recipient === ""
      ) {
        errs.recipient = this.$t("activerecord.models.receiver.one")+ " " + this.$t("errors.messages.required");
      }

      return errs;
    },
  },
  mounted(){
    this.delivery = this.buildEmptyDelivery();
    this.title = this.$t("activerecord.attributes.delivery.request_logistics_activities")
    if(this.oldDelivery) {
      this.addAttrsToDelivery()
      this.title = this.$t("common.change") + ' ' + this.$t("activerecord.attributes.delivery.request_logistics_activities")
      this.isOldDelivery = true
    }
  },

  methods: {
    canShowPromo(){
      return (this.delivery.service_type == "delivery" && !this.delivery.additional_data.extra_activity)
    },
    clearOrders(){
      if (confirm(this.$t("common.sure_to_clear_all_order"))){
        this.orders = [];
      }
    },
    createLayout(layoutKind){
      this.layoutKind = layoutKind;
      this.requestOrderLoading = true;
      var params = { layout_kind: layoutKind, delivery_kind: this.delivery.service_type  }
      RequestService.request_get(
        params,
        `senders/${this.senderId}/get_layout_orders`
      ).then(
        (res) => {
          this.requestOrderLoading = false;
          console.log(res)
          if (res.data.orders.length){
            res.data.orders.forEach( (order) => 
              {
                this.addOrderAndItem(order, order.items, false);
              }
            )
          }else{
            var snackbar = { color: '', text: ''}
            snackbar.color = 'error'
            snackbar.text = this.$t('common.no_order_to_add');
            this.addMessageOnSnackbar( snackbar );
          }
        },
        (error) => {
          this.requestOrderLoading =false;
          console.log(error)
        }
      );
    },
    handleAddressData(){
      if(!this.delivery.additional_data.extra_activity){
        this.removeAddressData();
      }
      if(this.delivery.additional_data.extra_activity){        
        if (this.orders.length > 1){
          var snackbar = { color: '', text: ''}
          snackbar.color = 'error'
          snackbar.text = this.$t("errors.messages.not_more_than_one_order_for_extra_activity");
          this.addMessageOnSnackbar( snackbar );
        }
        if (this.orders.length == 1){
          this.addAddressData(this.orders[0]);
        }else{
          this.removeAddressData();
        }
      }
    },
    removeAddressData(){
      this.delivery.street = null;
      this.delivery.town = null;
      this.delivery.zipcode = null;
      this.delivery.province = null;
      this.delivery.additional_data.recipient = null;
    },
    addAddressData(order){
      this.delivery.street = order.address_data.street;
      this.delivery.town = order.address_data.town;
      this.delivery.zipcode = order.address_data.zipcode;
      this.delivery.province = order.address_data.province;
      this.delivery.additional_data.recipient = order.receiver_data.name;
    },
    setFirstDateHour(){
      this.delivery.first_date = moment(this.delivery.first_date).set({hour:7,minute:0}).toISOString();
    },
    setLastDateHour(){
      this.delivery.last_date = moment(this.delivery.last_date).set({hour:20,minute:0}).toISOString();
    },
    closeDialog() {
      this.clearForm();
      this.$emit("close-dialog");
    },
    isValid() {
      var errs = this.errorMessages;
      if( errs.orders) { 
        this.snackbar = {
          color: "error",
          text: errs.orders,
          value: true,
          timeout: 5000,
        }
      }
      return Object.keys(errs).length === 0;
    },
    createNewDeliveryWithOrders() {
      var that = this;
      this.errorVisible = true;
      if (!this.isValid()) return;

      if (
        this.orders.length === 0
      ) {
        alert(that.$t("common.no_orders_select"));
        return;
      }

      var params = this.buildParams();
      if (
        params.delivery.delivery_items_attributes.length === 0
      ) {
        alert(that.$t("common.alert_select_at_least_one_item"))
        return;
      }

      var endpoint = `senders/${this.senderId}/create_delivery`
      if (this.isOldDelivery) {
        endpoint = `deliveries/${params.delivery.id}/update_logistics_activity`
      }
      RequestService.request_post(
        params,
        endpoint
      ).then(
        (res) => {
          (this.snackbar = {
            color: "success",
            text: "Consegna creata con successo",
            value: true,
            timeout: 5000,
          }),
            (that.loading = false);
          // this.sendMail(res.data);
          console.log(res)
          this.createLogisticService(res.data.id)
          if (this.isOldDelivery) {
            this.$emit("refresh-delivery-table");
          }
          that.closeDialog();
        },
        (error) => {
          this.$emit("error", error);
          (this.snackbar = {
            color: "error",
            text: error.response.data.errors.join("-"),
            value: true,
            timeout: 5000,
          }),
            console.log(error);
          that.loading = false;
        }
      );
    },
    createLogisticService(delivery_id){
      var params = { delivery_id: delivery_id}
      RequestService.request_post(
        params,
        `senders/${this.senderId}/create_logistic_service`
      ).then(
        (res) => {
          console.log(res)
        },
        (error) => {
          console.log(error)
        }
      );
    },
    addItem(item) {
      var order = JSON.parse(JSON.stringify(item.order));
      this.addOrderAndItem(order, [item], false);
    },
    addOrderOrItemDisabled(){
      if(this.delivery.additional_data.extra_activity){
        return this.orders.length < 1;
      }
      return true;
    },
    addOrder(value) {
      var order = value.order
      var excludeShopStock = value.excludeShopStock
      this.addOrderAndItem(order, order.items, excludeShopStock);
    },
    addOrderAndItem(order, itemToSelect, excludeShopStock) {
      var orderSaved = this.orders.find((o) => o.id == order.id);
      if (orderSaved) {
        itemToSelect.forEach((i) => {
          var item = orderSaved.items.find((j) => j.id == i.id);
          if (item) {
            item.quantityRequired = (item.stock || 0) / (item.unitary_pieces || 1);
            if (excludeShopStock && item.kind == "shop_stock") item.quantityRequired = 0;
          }
        });
      } else {
        order.items.map((item) => {
          if (itemToSelect.map((i) => i.id).includes(item.id)) {
            item.quantityRequired = (item.stock || 0) / (item.unitary_pieces || 1);
            if (excludeShopStock && item.kind == "shop_stock") item.quantityRequired = 0;
          }else{
            item.quantityRequired = 0;
          }
        });
        if (this.orders.length > 0) {
          this.orders.unshift(order);
        } else{
          this.orders.push(order);
        }
      }
      this.handleAddressData();
    },
    removeOrder(orderId) {
      this.orders = this.orders.filter((o) => o.id != orderId);
      this.handleAddressData();
    },
    updateOrder(orderId){
      var address = `orders/${orderId}/update_order_items`;
      var params = { id: orderId };
      var snackbar = { color: '', text: ''}
      var that = this;
      RequestService.request_post(params, address).then(
        (res) => {
          that.removeOrder(res.data.id)
          that.addOrder({order: res.data, excludeShopStock: false})
          snackbar.color = 'success'
          snackbar.text = this.$t("helpers.effects.updated.m.other", null, { model: this.$t("activerecord.models.item.other")});
          this.addMessageOnSnackbar( snackbar );
        },
        (error) => {
          console.log(error);
          snackbar.color = 'error'
          snackbar.text = error.response.data.errors[0];
          this.addMessageOnSnackbar( snackbar );
        }
      );
    },
    buildParams() {
      var deliveryParams = JSON.parse(JSON.stringify(this.delivery));
      this.orders.forEach((o) => {
        if (
          o.items
            .map((i) => i.quantityRequired)
            .filter((e) => !!e)
            .reduce((a, c) => a + c, 0) > 0
        ) {
          deliveryParams.order_deliveries_attributes.push(
            this.buildDeliveryOrderAttribute(o)
          );
          deliveryParams.delivery_items_attributes.push(
            ...o.items
              .filter((i) => i.quantityRequired > 0)
              .map((i) => this.buildDeliveryItemAttribute(i))
          );
        }
      });

      deliveryParams.first_date = moment(this.delivery.first_date).set({hour:7,minute:0}).toISOString();
      deliveryParams.last_date = moment(this.delivery.last_date).set({hour:20,minute:0}).toISOString();

      if (this.orders.length == 1 && this.providerName == "fip") {
        const [number, type, year] = this.orders[0].identifier.split("-");
        deliveryParams.additional_data.number = number;
        deliveryParams.additional_data.type = type;
        deliveryParams.additional_data.year = year;
      }

      if (!this.delivery.additional_data.extra_activity) {
        deliveryParams.province = this.senderLocation.province;
        deliveryParams.street = this.senderLocation.street;
        deliveryParams.town = this.senderLocation.town;
        deliveryParams.zipcode = this.senderLocation.zipcode;
        deliveryParams.latitude = this.senderLocation.latitude;
        deliveryParams.longitude = this.senderLocation.longitude;
        deliveryParams.additional_data.recipient = this.senderUsername;
        deliveryParams.shop_activity = true;
      }
      deliveryParams.additional_data.notes = this.delivery.notes;

      deliveryParams.additional_data.address = [
        deliveryParams.street,
        deliveryParams.zipcode,
        deliveryParams.town,
        deliveryParams.province,
      ].join(", ");
      return { delivery: deliveryParams };
    },
    buildEmptyDelivery() {
      return {
        identifier: this.deliveryWithOrderIdentifier,
        service_type: "delivery",
        first_date: moment().set({ hour: 7, minute: 0 }).toISOString(),
        last_date: null,
        sender_id: this.senderId,
        province: null,
        street: null,
        town: null,
        zipcode: null,
        latitude: null,
        longitude: null,
        notes: "",
        provider_id: this.$store.state.auth.session.user.provider_id,
        company_id: this.companyId,
        created_in_open: true,
        additional_data: {
          extra_activity: "",
          recipient: "",
        },
        order_deliveries_attributes: [],
        delivery_items_attributes: [],
      };
    },
    addAttrsToDelivery(){
      this.delivery.id = this.oldDelivery.id;
      this.getDeliveryItemsAndOrdersAttributes()
    },
    addAttrs(del, orders, del_items){
      this.delivery.identifier = del.identifier;
      this.delivery.service_type = del.service_type;
      this.delivery.first_date = del.first_date;
      this.delivery.last_date = del.last_date;
      this.delivery.sender_id = del.sender_id;
      this.delivery.province = del.province;
      this.delivery.street = del.street;
      this.delivery.town = del.town;
      this.delivery.zipcode = del.zipcode;
      this.delivery.latitude = del.latitude;
      this.delivery.longitude = del.longitude;
      this.delivery.notes = del.additional_data['notes'];
      this.delivery.provider_id = del.provider_id;
      this.delivery.company_id = del.company_id;
      this.delivery.created_in_open = del.created_in_open;
      this.delivery.additional_data = del.additional_data;
      this.delivery.last_logistic_service = del.last_logistic_service;
      orders.forEach((o) =>{
        o.items.map((item) => {
          var del_item = null;
          item.quantityRequired = 0;
          del_item = del_items.filter((i) => i.item_id == item.id)[0]
          if (del_item) {
            item.quantityRequired = del_item.quantity;
          }
        });
      })
    },
    getDeliveryItemsAndOrdersAttributes() {
      this.loading = true;
      var that = this;
      RequestService.request_get(
        {},
        `deliveries/${that.oldDelivery.id}/get_orders_and_items_attributes`
      ).then(
        (res) => {
          that.addAttrs(res.data.delivery, res.data.orders, res.data.items);
          that.orders = res.data.orders;
          that.loading = false;
        },
        (error) => {
          console.log(error)
          that.loading = false;
        }
      );
      
    },
    buildDeliveryOrderAttribute(order) {
      return {
        order_id: order.id,
        company_id: this.companyId,
      };
    },
    buildDeliveryItemAttribute(item) {
      return {
        item_id: item.id,
        quantity: item.quantityRequired,
        price: item.quantityRequired * item.unitary_price,
      };
    },
    clearForm() {
      this.delivery = this.buildEmptyDelivery();
      this.orders = [];
      // this.$refs.addOrderField.clear();
      // this.$refs.addItemField.clear();
    },
    sendMail(delivery) {
      var firstDate = moment(delivery.first_date).format("DD/MM/YY");
      var lastDate = moment(delivery.last_date).format("DD/MM/YY");
      var dateBetween = [
        firstDate !== "Invalid date" ? "dopo il " + firstDate : "",
        lastDate !== "Invalid date" ? "entro il " + lastDate : "",
      ]
        .filter((e) => e != "")
        .join(" e ");
      var serviceType =
        delivery.service_type == "delivery" ? "una consegna" : "un ritiro";
      var mailObject = `OPEN: richista ${serviceType} con id ${delivery.id}`;
      var mailBody = `Comunicazione da ${this.senderUsername}: %0A
                       %0A""" 
                       %0A Abbiamo richiesto ${serviceType} da svolgere ${dateBetween}
                       %0A
                       %0A""" 
                       %0A
                       %0A è possibile accedere alla consegna attraverso il seguente link: 
                       %0A ${APP_URL}deliveries/${delivery.id}
                       %0A 
                       %0A Buon lavoro`;
      var mailTo = this.companyEmail;

      window.location.href = `mailto:${mailTo}?subject=${mailObject}&body=${mailBody}`;
    },
    addMessageOnSnackbar(snackbar) {
      this.snackbar = {
        color: snackbar.color,
        text: snackbar.text,
        value: true,
        timeout: 5000,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>